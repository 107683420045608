import React from 'react'
import RootElement from './src/components/root-element'

// export const onRouteUpdate = ({ location }) => {
//   if (process.env.NODE_ENV !== 'production') {
//     return null
//   }

//   const pagePath = location ? location.pathname : undefined

//   setTimeout(() => {
//     if (typeof window.gtag === 'function') {
//       window.gtag('event', 'page_view', { page_path: pagePath })
//     }
//   }, 100)

//   return true
// }

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

// custom typefaces fontsource
import '@fontsource/cormorant-garamond/300.css' //  Weight light 300
import '@fontsource/cormorant-garamond/400.css' //  Weight light 300
import '@fontsource/cormorant-garamond/400-italic.css' //  Weight light 300
import '@fontsource/montserrat/200.css' //  Weight light 400
