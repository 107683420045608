import React from 'react'
import { Script } from 'gatsby'

const RootElement = ({ children }) => {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_MEASUREMENT_ID}`}
        strategy="post-hydrate"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="post-hydrate"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${process.env.GATSBY_GA_MEASUREMENT_ID}');`,
        }}
      />
      <>{children}</>
    </>
  )
}

export default RootElement
